import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import { createTheme, ThemeProvider } from "@material-ui/core"
import CssBaseline from "@material-ui/core/CssBaseline"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BioCard from "../components/blog-card"

const darkTheme = createTheme({
  palette: {
    type: "light",
  }
})


const BlogIndex = ({ data, location }) => {
  const intl = useIntl()
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const allPosts = data.allMarkdownRemark.nodes
  const lang = location.pathname.replaceAll('/', '')
  const posts = [];
  for (const post of allPosts) {
    const postLang = post.frontmatter.lang.replaceAll('/', '');
    if (postLang === lang) {
      posts.push(post);
    }
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />

      <Layout location={location} title={siteTitle}>
        <Seo title="Blog" />

        <h1>{intl.formatMessage({id: "last_updated"})}</h1>

          {/*<img src={'/jair_main.jpg'} alt="Logo" />
      <h2>{intl.formatMessage({ id: "home" })}</h2>*/}

        <ol style={{ listStyle: `none` }}>
          {posts.map(post => {
            return (
              <BioCard category={post.frontmatter.type} key={post.frontmatter.slug} postinfo={post}></BioCard>
            )
          })}
        </ol>

      </Layout>
    </ThemeProvider>
  )
}
export default BlogIndex

export const pageQuery = graphql`
query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      nodes {
        excerpt
        frontmatter {
          date
          title
          slug
          type
          lang
          description
          thumbnail {
            absolutePath
            publicURL
          }
        }
      }
    }
  }
`
